import noop from '@utils/noop';
import type { IconName } from '@xo-union/dist-iconography';
import Icon from '@xo-union/tk-component-icons';
import React, { useRef, useEffect, useState } from 'react';
import Styles from './SegmentedControl.scss';

export type SegmentedControlOption =
	| { value: string; label: string; iconName?: IconName } // Label required if iconName isn't provided
	| { value: string; iconName: IconName; label?: string }; // Icon required if label isn't provided

interface SegmentedControlProps {
	/** Options for the segmented control. Each option must have a value and at least one of label or iconName. */
	options: SegmentedControlOption[];

	/** Name attribute for the grouped radio inputs. */
	name: string;

	/** Currently selected value. */
	selectedValue: string;

	/** Callback invoked when the selected value changes. */
	onChange: (value: string) => void;

	/** Whether the options have equal width. If true, a CSS-only solution is used for indicator movement. Defaults to true. */
	fixedWidth?: boolean;

	/** Whether the indicator should animate during movement. Defaults to true. */
	isIndicatorAnimated?: boolean;
}

const SegmentedControl: React.FC<SegmentedControlProps> = ({
	options,
	name,
	selectedValue,
	onChange,
	fixedWidth = true,
	isIndicatorAnimated = true,
}) => {
	const rootRef = useRef<HTMLDivElement>(null);
	const [indicatorStyle, setIndicatorStyle] = useState({
		transform: '',
		width: '',
	});

	useEffect(() => {
		// Dynamic width calculation only needed if !fixedWidth
		if (fixedWidth) return;

		const rootElement = rootRef.current;
		if (!rootElement) return;

		const selectedElement = rootElement.querySelector<HTMLInputElement>(
			`input[value="${selectedValue}"]`,
		)?.parentElement as HTMLElement;

		if (selectedElement) {
			const { offsetWidth, offsetLeft } = selectedElement;
			setIndicatorStyle({
				transform: `translateX(${Math.max(offsetLeft - 1, 0)}px)`,
				width: `${offsetWidth}px`,
			});
		}
	}, [selectedValue, fixedWidth]);

	return (
		<div
			ref={rootRef}
			className={Styles.segmentedControlRoot}
			style={
				{
					'--options-count': options.length,
					'--selected-index': options.findIndex(
						(o) => o.value === selectedValue,
					),
				} as React.CSSProperties
			}
		>
			<span
				className={Styles.segmentedControlIndicator}
				style={{
					...(!fixedWidth && indicatorStyle),
					transition: isIndicatorAnimated
						? 'transform 200ms ease, width 200ms ease'
						: 'none',
				}}
			/>
			{options.map((option) => (
				<div
					key={option.value}
					onClick={(e) => {
						e.preventDefault();
						onChange(option.value);
					}}
					onKeyDown={noop}
					className={Styles.segmentedControlOption}
					style={
						{
							width: fixedWidth ? 'revert-layer' : 'auto',
							fontWeight: selectedValue === option.value ? 'bold' : 'normal',
						} as React.CSSProperties
					}
				>
					<input
						type="radio"
						name={name}
						id={`${name}-${option.value}`}
						value={option.value}
					/>
					<label htmlFor={`${name}-${option.value}`}>
						{option.iconName && <Icon name={option.iconName} />}
						{option.label && <>{option.label}</>}
					</label>
				</div>
			))}
		</div>
	);
};

export default SegmentedControl;
