import { Caption } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { type FC } from 'react';
import Styles from './ExpandingIconButton.scss';

interface ExpandingIconButtonProps {
	IconComponent: React.ReactNode;
	text: string;
	onClick?: () => void;
	onMouseEnter?: () => void;
	colorScheme: 'primary' | 'additional01';
	forcedExpanded?: boolean;
}

const ExpandingIconButton: FC<ExpandingIconButtonProps> = ({
	IconComponent,
	text,
	onClick,
	onMouseEnter,
	colorScheme,
	forcedExpanded = false,
}) => {
	const buttonClass = classNames(Styles.expandingIconButton, {
		[Styles.primary]: colorScheme === 'primary',
		[Styles.additional01]: colorScheme === 'additional01',
		[Styles.forcedExpanded]: forcedExpanded,
	});

	return (
		<button
			type="button"
			className={buttonClass}
			onClick={onClick}
			onKeyDown={onClick}
			onMouseEnter={onMouseEnter}
		>
			{IconComponent}
			<Caption bold={true} size="lg" color="yellow">
				{text}
			</Caption>
		</button>
	);
};

export default ExpandingIconButton;
