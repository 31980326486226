import fieldsFull from './fieldsFull';

const fragment = `
fragment FacetsRecurseFull on Facets {
  facets {
    ...FacetFieldsFull
    facets{
      ...FacetFieldsFull
      facets {
        ...FacetFieldsFull
        facets {
          ...FacetFieldsFull
          facets {
            ...FacetFieldsFull
            facets {
                ...FacetFieldsFull
              facets {
                  ...FacetFieldsFull
              }
            }
          }
        }
      }
    }
  }
}
${fieldsFull}
`;

export default fragment;
